import { authModules } from 'vccm-common';
import { invokeApig, invokeApigAnonymously } from '../libs/authLib';
import { vrsOperationsSite } from '../constants/global';
import { getApiPrefix } from '../libs/apiLib';
import {
  cleanObject
} from "./apiUtil";

const unversionedUrl = '/users';
const baseUrl = () => getApiPrefix() + unversionedUrl;

class UsersApi {
  static getAllUsers(version: any = undefined, paginationToken: any = undefined, companies: any = undefined, internalUsersOnly = false, filter = '') {
    return invokeApig({ gatewayName: 'USERS', path: baseUrl(), queryParams: ({ version, ...(paginationToken && { paginationToken }), ...(companies && { groups: companies }), ...(internalUsersOnly && { internalUsersOnly: '1' }), ...(filter && { filter })}) });
  }

  static getUserByEmail(userEmail: string, companies: any = undefined) {
    return invokeApig({ gatewayName: 'USERS', path: `${baseUrl()}/userbyemail`, queryParams: ({ userEmail, ...(companies ? { groups: companies } : {})}) });
  }

  static saveUser(user, messageAction) {
    console.log('saving User', user);
    return invokeApig({ gatewayName: 'USERS', path: baseUrl(), method: 'POST', body: user, queryParams: messageAction != null ? {messageAction} :{} });
  }

  static resetPassword(user) {
    console.log('reset User', user);
    const gatewayName = 'USERS';
    return invokeApig({ gatewayName, path: `${baseUrl()}/resetPassword`, method: 'POST', body: user });
  }

  static sendWelcomeEmail(user) {
    console.log('send welcome email User', user);
    const gatewayName = 'USERS';
    return invokeApigAnonymously({ gatewayName, path: `${baseUrl()}/sendWelcomeEmail`, method: 'POST', body: user });
  }

  static assignUser(userId, siteId, _, siteRoles) {
    return invokeApig({ gatewayName: 'USERS', method: 'PUT', path: `${baseUrl()}/${userId}/site-assign/${siteId}`, body: { siteRoles } });
  }

  static deleteUser(siteId, id) {
    const apiUrl = `${baseUrl()}/${id}`;
    return invokeApig({ gatewayName: 'USERS', path: apiUrl, method: 'DELETE', queryParams: { siteId } });
  }

  static createVrsUserRoles(userRoleCategories) {
    const apiUrl = `${baseUrl()}/createVrsUserRoles`;
    return invokeApig({ gatewayName: 'USERS', path: apiUrl, method: 'POST', body: userRoleCategories });
  }

  static getCirrusToken(siteId) {
    const apiUrl = `${baseUrl()}/tdtoken/${siteId}`;
    return invokeApig({ gatewayName: 'USERS', path: apiUrl, method: 'GET' });
  }

  static getVrsLoginToken(vjcToken, siteId) {
    const apiUrl = `${baseUrl()}/foreignAuthToken`;
    return invokeApig({ gatewayName: 'USERS', path: apiUrl, method: 'POST', body: { vjcToken }, queryParams: { siteId, authModule: authModules.VRS_AUTH_MODULE } });
  }

  static getVrsOperationLoginToken(vjcToken) {
    const apiUrl = `${baseUrl()}/foreignAuthToken`;
    return invokeApig({ gatewayName: 'USERS', path: apiUrl, method: 'POST', body: { vjcToken }, queryParams: { siteId: vrsOperationsSite.id, authModule: authModules.VRS_OPERATIONS_AUTH_MODULE } });
  }

  static migrateVrsUsers(queryParams) {
    const apiUrl = `${baseUrl()}/migrateVrsUsers`;
    return invokeApig({gatewayName: 'USERS', path: apiUrl, method: 'POST', queryParams: cleanObject(queryParams)})
  }
}

export default UsersApi;
