import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import { useLayoutState } from "../../context/LayoutContext/LayoutContext";
import { useProgressIndicatorStyles } from "./ProgressIndicator.css";

interface ProgressIndicatorProps {
  fullScreen?: boolean;
  withBackground?: boolean;
}

const ProgressIndicator = ({
  fullScreen,
  withBackground,
}: ProgressIndicatorProps) => {
  const classes = useProgressIndicatorStyles();
  const { isSidebarOpened } = useLayoutState();
  return (
    <div
      className={classNames({
        [classes.overlayWithDrawer]: !fullScreen && isSidebarOpened,
        [classes.overlay]: !(!fullScreen && isSidebarOpened),
        [classes.withBackground]: withBackground,
      })}
    >
      <div className={classes.spinner}>
        <div className={classes.placeholder}>
          <CircularProgress />
        </div>
      </div>
    </div>
  );
};

export { ProgressIndicator };
