import { useContext, useMemo } from "react";
import Icon from "@mdi/react";
import {
  mdiMapMarkerRadius,
  mdiMonitorDashboard,
  mdiAccountWrench,
  mdiCalculatorVariantOutline,
  mdiFinance,
} from "@mdi/js";

import {
  Gradient as GradientIcon,
  FormatShapes as FormatShapesIcon,
  Toc as TocIcon,
  Tune as TuneIcon,
  ViewComfy as ViewComfyIcon,
  LibraryBooks as LibraryBooksIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  SyncAlt as SyncAltIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Extension as ExtensionIcon,
} from "@mui/icons-material";

import ApiIcon from '@mui/icons-material/Api';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { getLinkAccessInfo } from "./RouteAccess";
// import UserHelper from './helpers/UserHelper';
import { ILinkStructure } from "./interfaces/ILinkStructure";

import {
  VideojetConnectDesign,
  VideojetConnectExchange,
  VideojetConnectInsight,
  VideojetConnectInspect,
  VideojetConnectRemoteService,
} from "./constants/global";
import {
  AppGlobalStateContext,
  useAppUserState,
  useVrsTranslationState,
} from "./context/AppContext/AppContext";
import { CogIcon } from "./components/CogIcon/CogIcon";
import { ReportIcon } from "./components/Basic/ReportIcon/ReportIcon";

export function useAppMenuStructureState(
  internalCallArray
): Array<ILinkStructure> {
  try {
    const context = useContext(AppGlobalStateContext);

    const { _T } = useVrsTranslationState();

    const { userProfileLarge } = useAppUserState();

    const isUserConfigImporter = !!userProfileLarge?.ListMember?.find(
        (el) => el.Id === "config_import" && el.MemberType === "admin"
    );

    const { isAdmin, isPureDesignUser } = context.userState;
    const { selectedCompanyId, selectedSiteIdForCompany } =
      context.companyState;
    const { selectedSiteId, vrsAbilities } = context.siteState;
    const site = context.siteState.getSelectedSite();

    const accessInfo = useMemo(
      () =>
        getLinkAccessInfo({
          site,
          isAdmin,
          isPureDesignUser,
          selectedSiteId,
          vrsAbilities,
          isUserConfigImporter,
          useForAuthorizationCheck: false
  }),
      [
        site,
        isAdmin,
        isPureDesignUser,
        selectedCompanyId,
        selectedSiteIdForCompany,
        selectedSiteId,
        vrsAbilities,
        isUserConfigImporter
      ]
    );

    const structure = useMemo(
      () => [
        {
          id: "videojet_connect_service_menu",
          label: VideojetConnectRemoteService,
          link: "",
          type: "title",
          capitalise: false,
          showAlways: true,
          children: [
            {
              id: "summary_menu",
              label: _T("Summary"),
              ...accessInfo(`/vrs/summary`),
              icon: <Icon path={mdiMonitorDashboard} size={1} />,
              siteModule: "vrs",
              extraModule: "vrsOperations",
              activeLinkIndicators: [`/vrs/summary`],
              hideIfModuleNotEnabled: true,
              hideIfNotEnabled: true,
            },
            {
              id: "site_dashboard_menu",
              label: _T("Site Dashboard"),
              ...accessInfo(`/vrs/sitedashboard<ts><sql>`),
              icon: <Icon path={mdiMonitorDashboard} size={1} />,
              siteModule: "vrs",
              extraModule: "vrsOperations",
              activeLinkIndicators: [`/vrs/sitedashboard`],
              hideIfModuleNotEnabled: true,
              hideIfNotEnabled: true,
            },
            {
              id: "vrs_operation_menu",
              siteModule: "vrs",
              extraModule: "vrsOperations",
              hideIfModuleNotEnabled: true,
              hideIfNotEnabled: true,
              label: _T("Site Map"),
              ...accessInfo(`/vrs/sitemap<ts><sql>`),
              icon: <Icon path={mdiMapMarkerRadius} size={1} />,
              activeLinkIndicators: [`sitemap`],
            },
            {
              id: "availability_menu",
              label: _T("Availability"),
              hideIfModuleNotEnabled: true,
              hideIfNotEnabled: true,
              ...accessInfo(`/vrs/availability<ts><sql>`),
              icon: <Icon path={mdiCalculatorVariantOutline} size={1} />,
              siteModule: "vrs",
              extraModule: "vrsOperations",
              activeLinkIndicators: [`/vrs/availability`],
            },
            {
              id: "production_menu",
              label: _T("Production"),
              hideIfModuleNotEnabled: true,
              hideIfNotEnabled: true,
              ...accessInfo(`/vrs/production<ts><sql>`),
              icon: <Icon path={mdiFinance} size={1} />,
              siteModule: "vrs",
              extraModule: "vrsOperations",
              activeLinkIndicators: [`/vrs/production`],
            },
            {
              id: "service_dashboard_menu",
              label: _T("Service Dashboard"),
              siteModule: "vrs",
              extraModule: "vrsOperations",
              hideIfModuleNotEnabled: true,
              hideIfNotEnabled: true,
              ...accessInfo(`/vrs/servicedashboard<ts><sql>`),
              icon: <Icon path={mdiAccountWrench} size={1} />,
              activeLinkIndicators: ["servicedashboard"],
            },
            {
              id: "vrs_reports_manu",
              label: _T("Reports"),
              siteModule: "vrs",
              extraModule: "vrsOperations",
              icon: ReportIcon,
              activeLinkIndicators: [],
              hideIfModuleNotEnabled: true,
              hideIfNotEnabled: true,
              children: [
                {
                  id: "printer_snapshot_report_menu",
                  type: "level3Menu",
                  label: _T("Printer Snapshot"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/snapshotreport<ts><sql>`),
                  icon: "",
                  activeLinkIndicators: ["snapshotreport"],
                },
                {
                  id: "printer_performance_report_menu",
                  type: "level3Menu",
                  label: _T("Printer Performance"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/performancereport<ts><sql>`),
                  icon: "",
                  activeLinkIndicators: ["performancereport"],
                },
                {
                  id: "data_download_menu",
                  type: "level3Menu",
                  label: _T("Data Download"),
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/datadownload<ts><sql>`),
                  icon: "",
                  activeLinkIndicators: ["datadownload"],
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                },
                {
                  id: "rapid_recovery_menu",
                  type: "level3Menu",
                  label: _T("Rapid Recovery"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/rapidrecoveryreport`),
                  icon: "",
                  activeLinkIndicators: ["rapidrecoveryreport"],
                },
                {
                  id: "consumable_usage_menu",
                  type: "level3Menu",
                  label: _T("Consumable Usage"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/consumablesreport<ts><sql>`),
                  icon: "",
                  activeLinkIndicators: ["consumablesreport"],
                },
                {
                  id: "printer_needing_attention_menu",
                  type: "level3Menu",
                  label: _T("Printers Needing Attention"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/unitrequiringattentionreport<ts><sql>`),
                  icon: "",
                  activeLinkIndicators: ["unitrequiringattentionreport"],
                },
                {
                  id: "vrs_usage_menu",
                  type: "level3Menu",
                  label: _T("VRS Usage"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/usagereport<ts><sql>`),
                  icon: "",
                  activeLinkIndicators: ["usagereport"],
                }
              ],
            },
          ],
        },
        {
          id: "exchange_divider",
          type: "divider",
        },
        {
          id: "exchange_menu",
          type: "title",
          label: VideojetConnectExchange,
          siteModule: "api",
          capitalise: false,
          showAlways: true,
          children: [
            {
              id: "getting_started_menu",
              label: _T("Getting Started"),
              ...accessInfo(`/api/gettingstarted`),
              icon: <InfoOutlinedIcon/>,
              siteModule: "api",
              activeLinkIndicators: ["/api/gettingstarted"],
            },
            {
              id: "api_explorer_menu",
              label: _T("REST API Explorer"),
              ...accessInfo(`/api/explorer`),
              icon: <ApiIcon />,
              siteModule: "api",
              activeLinkIndicators: ["/api/explorer"],
            }
          ]
        },
        {
          id: "indelligence_divider",
          type: "divider",
        },
        {
          id: "intelligence_menu",
          type: "title",
          label: VideojetConnectInsight,
          siteModule: "Intelligence",
          capitalise: false,
          showAlways: true,
          children: [
            {
              id: "performance_menu",
              label: _T("Performance"),
              ...accessInfo(`/site/${selectedSiteId}/lines/overview`),
              icon: <GradientIcon />,
              siteModule: "Intelligence",
              activeLinkIndicators: ["/lines/overview", "lines/performance"],
            },
            {
              id: "intelligence_reports_menu",
              label: _T("Reports"),
              ...accessInfo(`/site/${selectedSiteId}/report`),
              activeLinkIndicators: [
                "report/oee",
                "report/downtime",
                "report/shift-history",
                "report/product",
                "report/management",
              ],
              icon: <LibraryBooksIcon />,
              siteModule: "Intelligence",
              hideIfModuleNotEnabled: true,
              hideIfNotEnabled: true,
            },
          ],
        },
        {
          id: "inspect_divider",
          type: "divider",
          siteModule: "Inspect",
          hideIfModuleNotEnabled: true,
        },
        {
          id: "inspect_menu",
          type: "title",
          label: VideojetConnectInspect,
          siteModule: "Inspect",
          hideIfModuleNotEnabled: true,
          capitalise: false,
        },
        {
          id: "inspect_info_menu",
          label: _T("Inspection"),
          ...accessInfo(`/site/${selectedSiteId}/inspections`),
          icon: <PlaylistAddCheckIcon />,
          siteModule: "Inspect",
          hideIfModuleNotEnabled: true,
          activeLinkIndicators: [`/site/${selectedSiteId}/inspections`],
        },
        {
          id: "inspect_reports_menu",
          label: _T("Reports"),
          ...accessInfo(`/site/${selectedSiteId}/inspection/report`),
          icon: <TocIcon />,
          siteModule: "Inspect",
          hideIfModuleNotEnabled: true,
          activeLinkIndicators: ["/inspection/report"],
        },

        { id: "cirrus_divider", type: "divider" },
        {
          id: "cirrus_menu",
          label: VideojetConnectDesign,
          internalCall: () => internalCallArray["menuShowCirrus"](),
          link: "",
          type: "titleText",
          siteModule: "Design",
          icon: <FormatShapesIcon />,
          capitalise: false,
        },

        { id: "configuration_divider", type: "divider" },

        {
          id: "configuration_menu",
          type: "title",
          label: _T("Configuration"),
          hideIfNotEnabled: true,
          children: [
            {
              id: "remote_service_menu",
              label: "Remote Service",
              siteModule: "vrs",
              extraModule: "vrsOperations",
              icon: CogIcon,
              link: "remote_service",
              activeLinkIndicators: [],
              hideIfModuleNotEnabled: true,
              hideIfNotEnabled: true,
              children: [
                {
                  id: "vrs_users_menu",
                  type: "level3Menu",
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  label: _T("Users"),
                  ...accessInfo(`/vrs/users<ts><sql>`),
                  icon: "",
                  activeLinkIndicators: [`/vrs/users`],
                },
                {
                  id: "favorites_menu",
                  type: "level3Menu",
                  label: _T("Favorites"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/favorites`),
                  icon: "",
                  activeLinkIndicators: ["favorites"],
                },
                {
                  id: "companies_menu",
                  type: "level3Menu",
                  label: _T("Companies"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/companymanagement`),
                  icon: "",
                  activeLinkIndicators: ["companymanagement"],
                },
                {
                  id: "sites_menu",
                  type: "level3Menu",
                  label: _T("Sites"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/sitemanagement`),
                  icon: "",
                  activeLinkIndicators: ["sitemanagement"],
                },
                {
                  id: "res_menu",
                  type: "level3Menu",
                  label: _T("RES's"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/resmanagement`),
                  icon: "",
                  activeLinkIndicators: ["resmanagement"],
                },
                {
                  id: "devices_menu",
                  type: "level3Menu",
                  label: _T("Devices"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/devicemanagement`),
                  icon: "",
                  activeLinkIndicators: ["devicemanagement"],
                },
                {
                  id: "alerts_menu",
                  type: "level3Menu",
                  label: _T("Alerts"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/alertmanagement`),
                  icon: "",
                  activeLinkIndicators: ["alertmanagement"],
                },

                {
                  id: "notification_menu",
                  type: "level3Menu",
                  label: _T("Notification Logs"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/notificationlog`),
                  icon: "",
                  activeLinkIndicators: ["notificationlog"],
                },
                {
                  id: "parameterconfig_menu",
                  type: "level3Menu",
                  label: _T("Data Tags"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/datatags`),
                  icon: "",
                  activeLinkIndicators: ["datatags"],
                },
                {
                  id: "eventconfig_menu",
                  type: "level3Menu",
                  label: _T("Events"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/events`),
                  icon: "",
                  activeLinkIndicators: ["events"],
                },
                {
                  id: "articles_menu",
                  type: "level3Menu",
                  label: _T("SF Articles"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/salesforcearticles`),
                  icon: "",
                  activeLinkIndicators: ["salesforcearticles"],
                },
                {
                  id: "device_logs_menu",
                  type: "level3Menu",
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  label: _T("Device Logs"),
                  ...accessInfo(`/vrs/logmanagement`),
                  icon: "",
                  activeLinkIndicators: [`logmanagement`],
                },
                {
                  id: "work_schedule_menu",
                  type: "level3Menu",
                  label: _T("Work Schedule"),
                  siteModule: "vrs",
                  extraModule: "vrsOperations",
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                  ...accessInfo(`/vrs/workschedule`),
                  icon: "",
                  activeLinkIndicators: ["workschedule"],
                },
              ],
            },
            {
              id: "insight_menu",
              label: "Insight",
              siteModule: "Intelligence",
              icon: CogIcon,
              link: "insight",
              activeLinkIndicators: [],
              hideIfModuleNotEnabled: true,
              hideIfNotEnabled: true,
              children: [
                {
                  id: "insight_sites_menu",
                  type: "level3Menu",
                  label: _T("Sites"),
                  ...accessInfo("/sites"),
                  icon: "",
                  activeLinkIndicators: ["/sites", "setup/site"],
                  nonActiveLinkIndicators: ["/setup/site/0/add"],
                  nonActiveLinkIndicators2: ["sites/operations"],
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                },
                {
                  id: "insight_lines",
                  type: "level3Menu",
                  label: _T("Lines"),
                  siteModule: "Intelligence",
                  ...accessInfo(`/site/${selectedSiteId}/setup/lines`),
                  icon: "",
                  activeLinkIndicators: ["/setup/line"],
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                },
                {
                  id: "insight_products_menu",
                  type: "level3Menu",
                  label: _T("Products"),
                  siteModule: "Intelligence",
                  ...accessInfo(`/site/${selectedSiteId}/setup/products`),
                  icon: "",
                  activeLinkIndicators: ["/setup/product"],
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                },
                {
                  id: "insight_shifts_menu",
                  type: "level3Menu",
                  label: _T("Shifts"),
                  siteModule: "Intelligence",
                  ...accessInfo(`/site/${selectedSiteId}/shifts`),
                  icon: "",
                  activeLinkIndicators: ["/shifts", "/setup/shift/"],
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                },
                {
                  id: "insight_downtimes_menu",
                  type: "level3Menu",
                  siteModule: "Intelligence",
                  label: _T("Downtimes"),
                  ...accessInfo(
                    `/site/${selectedSiteId}/setup/downtimes/category`
                  ),
                  icon: "",
                  activeLinkIndicators: [
                    "/setup/downtimes/category",
                    "/setup/downtimes/subcategory",
                  ],
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                },
                {
                  id: "insight_dislaymode_menu",
                  type: "level3Menu",
                  label: _T("Display Mode"),
                  siteModule: "Intelligence",
                  ...accessInfo(`/site/${selectedSiteId}/setup/displayMode`),
                  icon: "",
                  activeLinkIndicators: ["/setup/displayMode"],
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                },
                {
                  id: "insight_deviceroles_menu",
                  type: "level3Menu",
                  label: _T("Device Role"),
                  siteModule: "Intelligence",
                  ...accessInfo(`/site/${selectedSiteId}/setup/deviceroles`),
                  icon: "",
                  activeLinkIndicators: ["/setup/deviceroles"],
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                },
                {
                  id: "insight_roles_menu",
                  type: "level3Menu",
                  siteModule: "Intelligence",
                  label: _T("Roles"),
                  ...accessInfo(`/site/${selectedSiteId}/setup/roles`),
                  icon: "",
                  activeLinkIndicators: ["/setup/roles"],
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                },
                {
                  id: "insight_users_menu",
                  type: "level3Menu",
                  label: _T("User Access"),
                  ...accessInfo(`/company/users`),
                  icon: "",
                  activeLinkIndicators: [`/company/users`],
                  hideIfModuleNotEnabled: true,
                  hideIfNotEnabled: true,
                },
              ],
            },

            {
              id: "inspection_setup_menu",
              label: _T("InspectionSetup"),
              ...accessInfo(`/site/${selectedSiteId}/setup/inspections`),
              icon: <ViewComfyIcon />,
              siteModule: "InspectDisabled",
              hideIfModuleNotEnabled: true,
              activeLinkIndicators: ["/setup/inspections"],
            },
            {
              id: "inspection_settings_menu",
              label: _T("InspectionSettings"),
              ...accessInfo(`/site/${selectedSiteId}/setup/compliance`),
              icon: <TuneIcon />,
              siteModule: "InspectDisbled",
              hideIfModuleNotEnabled: true,
              activeLinkIndicators: ["/setup/compliance"],
            },
          ],
        },
        {
          id: "administrator_divider",
          requireAdmin: true,
          type: "divider",
        },

        {
          id: "administrator_menu",
          type: "title",
          requireAdmin: true,
          label: _T("Administrator"),
          children: [
            {
              id: "admin_site_configuration_menu",
              label: _T("Admin Site Configuration"),
              requireAdmin: true,
              ...accessInfo(`/site/${selectedSiteId}/admin`),
              disabled:
                !selectedSiteId ||
                selectedSiteId === "0" ||
                selectedSiteId === "VrsInternal",
              icon: <SupervisedUserCircleIcon />,
              activeLinkIndicators: [`/site/${selectedSiteId}/admin`],
            },
            {
              id: "manage_vrs_translation_menu",
              label: _T("Manage Translations"),
              requireAdmin: true,
              ...accessInfo(`/vrs/translations`),
              icon: <SyncAltIcon />,
              activeLinkIndicators: ["vrs/translations"],
            },
            {
              id: "operations_menu",
              label: _T("Operations"),
              requireAdmin: true,
              ...accessInfo("/sites/operations"),
              icon: <ExtensionIcon />,
              activeLinkIndicators: ["sites/operations"],
            },
          ],
        },
      ],
      [selectedSiteId, accessInfo, internalCallArray, _T]
    );

    return structure;
  } catch (err) {
    throw new Error(
      "useAppMenuStructureState must be used within a functional component"
    );
  }
}
