import { useEffect, useMemo, useCallback, useState } from "react";
import Joi from "joi";
import toastr from "toastr";
import {
  useAppSiteState,
  useConfigState,
  useVrsTranslationState,
} from "../../context/AppContext/AppContext";
import { extractErrors } from "../../libs/getDynoErrors";

import { IUserProfile } from "../../interfaces/User/IUserProfile";

import {
  containsLowercase,
  containsNumber,
  containsUppercase,
  isMinLength,
} from "../../utilities/validations";

import { useVrsUserActions } from "../../actions/vrsUserActions";
import { useConfigActions } from "../../actions/configActions";
import UserHelper from "../../helpers/UserHelper";
import { useLocalStorage } from "../../context/LocalStorageContext/LocalStorageContext";

interface IUseProfileProps {
  onClose?: () => void;
  includeAlerts: boolean;
}
// dptofile hook
export const useProfile = function ({
  onClose,
  includeAlerts,
}: IUseProfileProps) {
  // State and setters for debounced value

  const { _T, loadVrsTranslations, loadVrsUserProfile } =
    useVrsTranslationState();

  const vrsUserActions = useVrsUserActions();
  const configActions = useConfigActions();

  const [resetIndex, setResetIndex] = useState(0);

  const [refreshIndex, setRefreshIndex] = useState(0);

  const siteState = useAppSiteState();
  const { vrsAbilities } = siteState;

  const [errors, setErrors] = useState<any>({});
  const [saving, setSaving] = useState(false);
  const [submitActivated, setSubmitActivated] = useState(false);
  const [currentProfile, setCurrentProfile] = useState<IUserProfile | null>(
    null
  );
  const [dataReady, setDataReady] = useState(false);

  const [orgCurrentProfile, setOrgCurrentProfile] = useState<any>(null);
  const [dirty, setDirty] = useState(false);
  const [scheduleDirty, setScheduleDirty] = useState(false);

  const canAccessVrsWithField = (field) =>
    !!UserHelper.CanWithField("vrs", "authModule", field);

  const configState = useConfigState();
  const userLocalStorage = useLocalStorage();

  useEffect(() => {
    (async () => {
      const profiles = await vrsUserActions.getUserProfileAppSync(
        includeAlerts
      );

      const storageValue = userLocalStorage.getItem("vrsUserLocale");
      let vrsUserLocalStr =
        storageValue && storageValue !== "null" ? storageValue : "";

      const userProfile = profiles.length > 0 ? profiles[0] : null;
      configActions.setPrefer24HourTimeFormat(
        userProfile?.Prefer24HourTimeFormat || false
      );

      if (userProfile && userProfile.Languages && userProfile.LanguageId) {
        const languageProfileItem = userProfile.Languages.find(
          (el) => el.LanguageId === userProfile.LanguageId
        );
        const languageTag = languageProfileItem.IetflanguageTag;
        if (languageProfileItem) {
          if (languageTag && languageTag === "en-US") {
            vrsUserLocalStr = "en";
          } else {
            vrsUserLocalStr = languageProfileItem.IetflanguageTag.toLowerCase();
          }
        }
      }

      userLocalStorage.setItem("vrsUserLocale", vrsUserLocalStr);
      configActions.setVrsUserLocale(vrsUserLocalStr);
      setCurrentProfile(userProfile);
      setOrgCurrentProfile({ ...userProfile });
      setDataReady(true);
      if (configState.ProfileChangeIndex > 0) {
        setRefreshIndex((r) => r + 1);
      }
    })();
  }, [vrsUserActions, configState.ProfileChangeIndex]);

  useEffect(() => {
    if (dataReady) {
      setDirty(
        JSON.stringify(currentProfile) !== JSON.stringify(orgCurrentProfile)
      );
    }
  }, [dataReady]); // eslint-disable-line react-hooks/exhaustive-deps

  // if state we care changes, reevaluate dirty
  useEffect(() => {
    if (dataReady && currentProfile && orgCurrentProfile) {
      setDirty(
        JSON.stringify(currentProfile) !== JSON.stringify(orgCurrentProfile)
      );
      if (currentProfile.WorkSchedules && orgCurrentProfile.WorkSchedules) {
        setScheduleDirty(
          JSON.stringify(currentProfile.WorkSchedules) !==
            JSON.stringify(orgCurrentProfile.WorkSchedules)
        );
      }
    }
  }, [currentProfile, orgCurrentProfile, dataReady]); // eslint-disable-line react-hooks/exhaustive-deps

  const isCountrySuperUser = !!(vrsAbilities?.vrsSuperUserAbilities || []).find(
    (el) =>
      el.subject.includes("vrsSuperUser-Country_") && el.actions === "Enabled"
  );

  const isExternalUser =
    canAccessVrsWithField("Administrator") ||
    canAccessVrsWithField("Maintenance") ||
    canAccessVrsWithField("User");

  const validateUserProfile = useMemo(
    () =>
      Joi.object().keys({
        EmailAddress: Joi.string()
          .email()
          .lowercase() // convert lowercase
          .required()
          .error(() => _T("The Email Address field is required.")),
        NotificationEmail: Joi.string()
          .email()
          .lowercase() // convert lowercase
          .required()
          .error(() => _T("The Email Address field is required.")),
        CurrentPassword: Joi.string()
          .regex(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)
          .error(() => _T("Current password field is required.")),
        NewPassword: Joi.string()
          .regex(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)
          .error(() => _T("New password field is required.")),
        ConfirmPassword: Joi.any()
          .equal(Joi.ref("NewPassword"))
          .error(() => _T("Confirm password field is required.")),
        FirstName: Joi.string()
          .required()
          .error(() => _T("The First Name field is required.")),
        LastName: Joi.string()
          .required()
          .error(() => _T("The Last Name field is required.")),
        LanguageId: Joi.number()
          .required()
          .error(() => _T("Language field is required.")),
        Country: !isExternalUser
          ? Joi.string()
              .required()
              .error(() => _T("Country field is required."))
          : Joi.string().error(() => _T("Country field is required.")),
        AccessLevel: Joi.string()
          .required()
          .error(() => _T("Access level field is required.")),
        AccessLevelId: Joi.string()
          .required()
          .error(() => _T("Access level id field is required.")),
        Prefer24HourTimeFormat: Joi.boolean()
          .required()
          .error(() => _T("Prefer 24 Hour Time is required field")),
        DisplayUnit: Joi.string()
          .required()
          .error(() => _T("Display Unit is required field")),
        PlantIDs: Joi.string()
          .required()
          .allow("")
          .error(() => _T("PlantIDs is required field")),
        WorkSchedules: Joi.object(),
      }),
    [_T, isExternalUser]
  );

  const profileFormIsValid = useCallback(
    (profileToValidate) => {
      let formIsValid = true;
      let errors = {};

      if (profileToValidate?.Languages) {
        delete profileToValidate.Languages;
      }

      if (profileToValidate?.UserId) {
        delete profileToValidate?.UserId;
      }

      if (profileToValidate?.PhoneNumber === "") {
        delete profileToValidate?.PhoneNumber;
      }

      if (isExternalUser && profileToValidate?.Country === "") {
        delete profileToValidate?.Country;
      }

      if (profileToValidate?.CurrentPassword === "") {
        delete profileToValidate?.CurrentPassword;
      }

      if (profileToValidate?.NewPassword === "") {
        delete profileToValidate?.NewPassword;
      }

      if (profileToValidate?.ConfirmPassword === "") {
        delete profileToValidate?.ConfirmPassword;
      }

      if (profileToValidate?.UserAlerts) {
        delete profileToValidate?.UserAlerts;
      }

      if (
        profileToValidate?.ServiceOutageStatus ||
        profileToValidate?.ServiceOutageStatus === null
      ) {
        delete profileToValidate?.ServiceOutageStatus;
      }

      if (
        profileToValidate?.ListMember ||
        profileToValidate?.ListMember === null
      ) {
        delete profileToValidate?.ListMember;
      }

      const results = validateUserProfile.validate(profileToValidate, {
        abortEarly: false,
      });

      const newPassword = currentProfile?.NewPassword;

      const checks = {
        passwordMatches:
          newPassword && newPassword === currentProfile?.ConfirmPassword,
        isMinLength: isMinLength(newPassword, 8),
        containsNumber: containsNumber(newPassword),
        containsUppercase: containsUppercase(newPassword),
        containsLowercase: containsLowercase(newPassword),
      };

      const allChecksPass = Object.keys(checks).reduce(
        (acc, el) => acc && checks[el],
        true
      );

      errors = results.error
        ? results.error.details.reduce((agg, d) => {
            agg[d.context.key] = d.message ? d.message : "";
            formIsValid = false;
            return agg;
          }, {})
        : {};

      if (!currentProfile?.CurrentPassword && currentProfile?.NewPassword) {
        errors["CurrentPassword"] = _T("Current password field is required.");
        formIsValid = false;
      }

      setErrors(errors);
      return formIsValid && (!newPassword || allChecksPass);
    },
    [_T, currentProfile, validateUserProfile, isExternalUser]
  );

  const saveProfile = useCallback(async () => {
    setSubmitActivated(true);

    const profileToValidate = { ...currentProfile } as IUserProfile;

    if (!profileFormIsValid(profileToValidate)) {
      return;
    }
    setSaving(true);
    configActions.setIsLoading(true);

    vrsUserActions
      .saveUserProfileAppSync(profileToValidate, includeAlerts)
      .then(async (profileResult) => {
        const profile = profileResult.data;
        if (profile) {
          setCurrentProfile({ ...profile } as IUserProfile);
          setOrgCurrentProfile({ ...profile } as IUserProfile);

          const localeStr = await loadVrsUserProfile(true);
          const activeLocal = localeStr || navigator.language.split(/[-_]/)[0];
          configActions.setLocale(activeLocal);
          await loadVrsTranslations(activeLocal, true);
          setSaving(false);
          if (onClose) {
            onClose();
          }
        } else if (profileResult.error) {
          const errStr = extractErrors(profileResult.error);
          toastr.error(errStr || _T("Profile not saved!"));
        } else {
          toastr.error(_T("Profile not saved!"));
          setSaving(false);
        }
      })
      .catch((error) => {
        const errStr = extractErrors(error);
        toastr.error(errStr || error);
        setSaving(false);
      })
      .finally(() => {
        configActions.setIsLoading(false);
      });
  }, [
    _T,
    currentProfile,
    loadVrsTranslations,
    loadVrsUserProfile,
    profileFormIsValid,
    vrsUserActions,
    onClose,
    configActions,
  ]);

  useEffect(() => {
    if (submitActivated && currentProfile) {
      const supportRequestToValidate = {
        ...currentProfile,
      } as IUserProfile;

      profileFormIsValid(supportRequestToValidate);
    }
  }, [currentProfile, profileFormIsValid, submitActivated]);

  const [timeErrorObject, setTimeErrorObject] = useState<any>({});

  const [timeError, setTimeError] = useState(false);

  const onTimeError = useCallback((day, label: string, value: boolean) => {
    setTimeErrorObject((r) => ({ ...r, [`${label}_${day}`]: value }));
  }, []);

  useEffect(() => {
    setTimeError(
      !!Object.keys(timeErrorObject)
        .map((key) => timeErrorObject[key])
        .find((el) => el)
    );
  }, [timeErrorObject]);

  return {
    currentProfile,
    setCurrentProfile,
    saveProfile,
    resetIndex,
    refreshIndex,
    setResetIndex,
    timeError,
    onTimeError,
    dataReady,
    dirty,
    scheduleDirty,
    errors,
    saving,
    isExternalUser,
    isCountrySuperUser,
  };
};
