import { invokeAppSync } from '../libs/authLib';
import { getApiPrefix } from '../libs/apiLib';
import config from '../config';
import { timeScaleOptions } from './apiUtil';

const baseAppSyncUrl = () => getApiPrefix();

const checkAppSyncUrlExists = () => {
  return !!config.appSync['VRS_APPSYNC_SYSTEM_URL'];
};

function GetFriendlyName(name: string ) {
  if(name.includes('VRSApi'))
    return 'VRS Api Version';
  if(name.includes('VideojetS3TimescaleImport'))
    return 'Ingestion Version'
  return name;
}

class VrsDeploymentInfoApi {
  static getDeploymentInfo() {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({
        error: null,
        data: null,
      });
    }

    const [options, queryExtra] = timeScaleOptions();

    const graphQLBody = {
      query: `
      query DeploymentInfoQuery${queryExtra} {
        deployedVersion(${options}) {
          Name
          Version
        }
      }
        `,
    };

    return invokeAppSync({
      gatewayName: 'VRS_APPSYNC_SYSTEM',
      path: baseAppSyncUrl(),
      method: 'POST',
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.deployedVersion) {
        return {
          error: null,
          data: results.data?.deployedVersion?.map( (i: { Name: string; }) => ({...i, Name: GetFriendlyName(i.Name)})) || [],
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }
}

export default VrsDeploymentInfoApi;
