import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import App from "./App";
import { videojetConnectTheme } from "./Theme";
import { useConfigState } from "./context/AppContext/AppContext";
import { useMemo } from "react";
import enGBLocale from 'date-fns/locale/en-GB';
import enUSLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { esES as esDateLocale } from '@mui/x-data-grid/locales';
import deLocale from 'date-fns/locale/de';
import { deDE as deDateLocale } from '@mui/x-data-grid/locales';
import frLocale from 'date-fns/locale/fr';
import { frFR as frDateLocale } from '@mui/x-data-grid/locales';
import itLocale from 'date-fns/locale/it';
import { itIT as itDateLocale } from '@mui/x-data-grid/locales';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { ptBR as ptBRDateLocale } from '@mui/x-data-grid/locales';
import nlLocale from 'date-fns/locale/nl';
import { nlNL as nlDateLocale } from '@mui/x-data-grid/locales';
import trLocale from 'date-fns/locale/tr';
import { trTR as trDateLocale } from '@mui/x-data-grid/locales';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const localeMap = [
    { id: 'en', locale: enGBLocale },
    { id: 'en-US', locale: enUSLocale },
    { id: 'es', locale: esLocale, dateLocale: esDateLocale },
    { id: 'de', locale: deLocale, dateLocale: deDateLocale },
    { id: 'fr', locale: frLocale, dateLocale: frDateLocale },
    { id: 'it', locale: itLocale, dateLocale: itDateLocale },
    { id: 'pt', locale: ptBRLocale, dateLocale: ptBRDateLocale },
    { id: 'nl', locale: nlLocale, dateLocale: nlDateLocale },
    { id: 'tr', locale: trLocale, dateLocale: trDateLocale },
];


export const AppWrapper = () => {

    const { locale } = useConfigState();

    const gridTranslationsLocale = useMemo(() => {
        const localeItem = localeMap.find(x => x.id === locale);
        if (localeItem) {
            return localeItem.dateLocale ?? {};
        } else {
            console.log(`Locale ${locale} not found in localeMap`);
        }
        return {};
    }, [locale]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={videojetConnectTheme(gridTranslationsLocale ?? {})}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CssBaseline />
                    <App />
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider >
    );
}

