import { isTimescaleUrl } from "../utilities/utils";

export const cleanObject = (object: { [x: string]: any }) => {
  const n = {};
  Object.keys(object).forEach((key) => {
    if (object[key]) {
      n[key] = object[key];
    }
  });
  return n;
};

export const graphQlOptionsArg = () => {
  return `options: ${isTimescaleUrl() ? '["ts"]' : "[]"}`;
};

export function timeScaleOptions() {
  const timescaleOptions = graphQlOptionsArg();
  return [
    timescaleOptions,
    timescaleOptions.includes("ts") ? "WithTimescale" : "",
  ];
}
