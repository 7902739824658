//* *****************************************************************************
// Action Types Enumeration
// _SUCCESS indicates the completion on a successful ajax call action
//* *****************************************************************************

// Assessments
export const LOAD_ASSESSMENT_SUCCESS = "LOAD_ASSESSMENT_SUCCESS";
export const LOAD_ASSESSMENTS_SUCCESS = "LOAD_ASSESSMENTS_SUCCESS";
export const CREATE_ASSESSMENT_SUCCESS = "CREATE_ASSESSMENT_SUCCESS";
export const UPDATE_ASSESSMENT_SUCCESS = "UPDATE_ASSESSMENT_SUCCESS";

// Config
export const LOAD_CONFIG_SUCCESS = "LOAD_CONFIG_SUCCESS";

// Dashboards
export const DELETE_DASHBOARD_SUCCESS = "DELETE_DASHBOARD_SUCCESS";
export const DELETE_DEVICE_SUCCESS = "DELETE_DEVICE_SUCCESS";
export const LOAD_DASHBOARDS_SUCCESS = "LOAD_DASHBOARDS_SUCCESS";
export const LOAD_DEVICES_SUCCESS = "LOAD_DEVICES_SUCCESS";
export const UPDATE_DASHBOARD_SUCCESS = "UPDATE_DASHBOARD_SUCCESS";
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS";

// Downtime Category Setup
export const CREATE_DOWNTIME_CATEGORY_SUCCESS =
  "CREATE_DOWNTIME_CATEGORY_SUCCESS";
export const LOAD_DOWNTIME_CATEGORY_SUCCESS = "LOAD_DOWNTIME_CATEGORY_SUCCESS";
export const UPDATE_DOWNTIME_CATEGORY_SUCCESS =
  "UPDATE_DOWNTIME_CATEGORY_SUCCESS";
export const DELETE_DOWNTIME_CATEGORY_SUCCESS =
  "DELETE_DOWNTIME_CATEGORY_SUCCESS";

// Downtime Events
export const CREATE_DOWNTIME_EVENT_SUCCESS = "CREATE_DOWNTIME_EVENT_SUCCESS";
export const LOAD_DOWNTIME_EVENTS_SUCCESS = "LOAD_DOWNTIME_EVENTS_SUCCESS";
export const LOAD_DOWNTIME_EVENTS_BY_SUCCESS =
  "LOAD_DOWNTIME_EVENTS_BY_SUCCESS";
export const UPDATE_DOWNTIME_EVENT_SUCCESS = "UPDATE_DOWNTIME_EVENT_SUCCESS";
export const DELETE_DOWNTIME_EVENT_SUCCESS = "DELETE_DOWNTIME_EVENT_SUCCESS";

// Inspections
export const LOAD_INSPECTION_SUCCESS = "LOAD_INSPECTION_SUCCESS";
export const LOAD_INSPECTIONS_SUCCESS = "LOAD_INSPECTIONS_SUCCESS";
export const CREATE_INSPECTION_SUCCESS = "CREATE_INSPECTION_SUCCESS";
export const UPDATE_INSPECTION_SUCCESS = "UPDATE_INSPECTION_SUCCESS";
export const DELETE_INSPECTION_SUCCESS = "DELETE_INSPECTION_SUCCESS";

// Line
export const CREATE_QA_COUNT_SUCCESS = "CREATE_QA_COUNT_SUCCESS";
export const CREATE_LINE_SUCCESS = "CREATE_LINE_SUCCESS";
export const DELETE_LINE_SUCCESS = "DELETE_LINE_SUCCESS";
export const LOAD_JOB_SUCCESS = "LOAD_JOB_SUCCESS";
export const LOAD_LINES_INITIATED = "LOAD_LINES_INITIATED";
export const LOAD_LINES_SUCCESS = "LOAD_LINES_SUCCESS";
export const LOAD_LINES_OVERVIEW_INITIATED = "LOAD_LINES_OVERVIEW_INITIATED";
/* export const LOAD_LINES_SUMMARY_SUCCESS = 'LOAD_LINES_SUMMARY_SUCCESS'; */
export const LOAD_LINE_OEE_SUCCESS = "LOAD_LINE_OEE_SUCCESS";
export const LOAD_LINE_OEE_HOUR_SUCCESS = "LOAD_LINE_OEE_HOUR_SUCCESS";
export const LOAD_LINE_OEE_DAY_SUCCESS = "LOAD_LINE_OEE_DAY_SUCCESS";
export const LOAD_LINE_OEE_SHIFT_SUCCESS = "LOAD_LINE_OEE_SHIFT_SUCCESS";
export const LOAD_LINES_OVERVIEW_SUCCESS = "LOAD_LINES_OVERVIEW_SUCCESS";
export const LOAD_LINE_SHIFT_TARGETS_SUCCESS =
  "LOAD_LINE_SHIFT_TARGETS_SUCCESS";
export const LOAD_LINE_SLOW_CYCLES_SUCCESS = "LOAD_LINE_SLOW_CYCLES_SUCCESS";
export const LOAD_LINE_THROUGHPUT_BY_DAY_SUCCESS =
  "LOAD_LINE_THROUGHPUT_BY_DAY_SUCCESS";
export const LOAD_LINE_TIME_IN_STATE_SUCCESS =
  "LOAD_LINE_TIME_IN_STATE_SUCCESS";
export const LOAD_LINE_UTILIZATION_SUCCESS = "LOAD_LINE_UTILIZATION_SUCCESS";
export const SET_LINE = "SET_LINE";
export const UPDATE_LINE_SUCCESS = "UPDATE_LINE_SUCCESS";
export const LOAD_LINE_DOWNTIME_SUMMARY_SUCCESS =
  "LOAD_LINE_DOWNTIME_SUMMARY_SUCCESS";

// Product
export const CLONE_PRODUCT_SUCCESS = "CLONE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const LOAD_PRODUCTS_INITIATED = "LOAD_PRODUCTS_INITIATED";
export const LOAD_PRODUCTS_SUCCESS = "LOAD_PRODUCTS_SUCCESS";
export const LOAD_PRODUCT_SUCCESS = "LOAD_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";

// Role
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const LOAD_ROLES_SUCCESS = "LOAD_ROLES_SUCCESS";
export const LIST_ABILITIES_SUCCESS = "LIST_ABILITIES_SUCCESS";

// DeviceRole
export const CREATE_DEVICE_ROLE_SUCCESS = "CREATE_DEVICE_ROLE_SUCCESS";
export const UPDATE_DEVICE_ROLE_SUCCESS = "UPDATE_DEVICE_ROLE_SUCCESS";
export const DELETE_DEVICE_ROLE_SUCCESS = "DELETE_DEVICE_ROLE_SUCCESS";
export const LOAD_DEVICE_ROLES_SUCCESS = "LOAD_DEVICE_ROLES_SUCCESS";

// Shift
export const CREATE_SHIFT_SUCCESS = "CREATE_SHIFT_SUCCESS";
export const DELETE_SHIFT_SUCCESS = "DELETE_SHIFT_SUCCESS";
export const LOAD_SHIFTS_INITIATED = "LOAD_SHIFTS_INITIATED";
export const LOAD_SHIFTS_SUCCESS = "LOAD_SHIFTS_SUCCESS";
export const UPDATE_SHIFT_SUCCESS = "UPDATE_SHIFT_SUCCESS";
export const LOAD_SHIFTSATMIN_SUCCESS = "LOAD_SHIFTSATMIN_SUCCESS";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const UPDATE_DETAILS = "UPDATE_DETAILS";

// Site
export const LOAD_SITE_DOWNTIME_DAY_SUMMARY_SUCCESS =
  "LOAD_SITE_DOWNTIME_DAY_SUMMARY_SUCCESS";
export const LOAD_SITE_DOWNTIME_SUMMARY_SUCCESS =
  "LOAD_SITE_DOWNTIME_SUMMARY_SUCCESS";
export const LOAD_SITE_DOWNTIME_SUMMARY_FAILURE =
  "LOAD_SITE_DOWNTIME_SUMMARY_FAILURE";
export const LOAD_SITE_OEE_SUMMARY_SUCCESS = "LOAD_SITE_OEE_SUMMARY_SUCCESS";
export const LOAD_SITE_OEE_SUMMARY_FAILURE = "LOAD_SITE_OEE_SUMMARY_FAILURE";
export const LOAD_SITE_PRINTERS_INITIATED = "LOAD_SITE_PRINTERS_INITIATED";
export const LOAD_SITE_PRINTERS_SUCCESS = "LOAD_SITE_PRINTERS_SUCCESS";
export const LOAD_SITE_PRODUCT_SUMMARY_SUCCESS =
  "LOAD_SITE_PRODUCT_SUMMARY_SUCCESS";

export const CREATE_SITE_SUCCESS = "CREATE_SITE_SUCCESS";
export const LOAD_SITE_DOWNTIME_CATEGORIES_INITIATED =
  "LOAD_SITE_DOWNTIME_CATEGORIES_INITIATED";
export const LOAD_SITE_DOWNTIME_CATEGORIES_SUCCESS =
  "LOAD_SITE_DOWNTIME_CATEGORIES_SUCCESS";
export const LOAD_SITE_SUCCESS = "LOAD_SITE_SUCCESS";
export const LOAD_ANOTHER_SITE_SUCCESS = "LOAD_ANOTHER_SITE_SUCCESS";
export const LOAD_COMPANY_SUCCESS = "LOAD_COMPANY_SUCCESS";
export const LOAD_SITE_SUMMARY = "LOAD_SITE_SUMMARY";
export const LOAD_SITES_SUCCESS = "LOAD_SITES_SUCCESS";
export const LOAD_MULTI_SITES_SUCCESS = "LOAD_MULTI_SITES_SUCCESS";
export const LOAD_MULTI_SITES_INITIATED = "LOAD_MULTI_SITES_INITIATED";
export const LOAD_MULTI_SITES_FAILED = "LOAD_MULTI_SITES_FAILED";
export const SET_SITE = "SET_SITE";
export const SET_FILTER = "SET_FILTER";
export const SET_LANGUAGE_ITEMS = "SET_LANGUAGE_ITEMS";
export const SET_VRS_USER_LOCALE = "SET_VRS_USER_LOCALE";
export const SET_CACHE = "SET_CACHE";
export const SET_DATA_INITIALIZATION = "SET_DATA_INITIALIZATION";
export const SET_APP_DATA_INITIALIZATION = "SET_APP_DATA_INITIALIZATION";
export const SET_INNER_STATE = "SET_INNER_STATE";
export const RESET_INNER_STATE = "RESET_INNER_STATE";
export const SET_SINGLE_SITE = "SET_SINGLE_SITE";
export const SET_SITE_ID = "SET_SITE_ID";
export const SET_TITLE = "SET_TITLE";
export const SET_DASHBOARD_MODE = "SET_DASHBOARD_MODE";
export const UPDATE_SITE_SUCCESS = "UPDATE_SITE_SUCCESS";
export const DELETE_SITE_SUCCESS = "DELETE_SITE_SUCCESS";
export const SET_LOCALE = "SET_LOCALE";
export const SET_PREFERRED_24_HOUR_FORMAT = "SET_PREFERRED_24_HOUR_FORMAT";
export const SET_HAS_COMPANY_ACCESS = "SET_HAS_COMPANY_ACCESS";
export const SET_HAS_SITE_ACCESS = "SET_HAS_SITE_ACCESS";
export const SET_QUERY_STRING_CHECKED = "SET_QUERY_STRING_CHECKED";
export const SET_TRACKER_ACTION_OBJECT = "SET_TRACKER_ACTION_OBJECT";
export const SET_PROFILE_CHANGED = "SET_PROFILE_CHANGED"

// User
export const ASSIGN_USER_SUCCESS = "ASSIGN_USER_SUCCESS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_COMPANIES_INITIATED = "LOAD_COMPANIES_INITIATED";
export const LOAD_COMPANIES_SUCCESS = "LOAD_COMPANIES_SUCCESS";
export const LOAD_COMPANIES_FAILED = "LOAD_COMPANIES_FAILED";
export const SET_COMPANY_ID_AND_SITE_ID = "SET_COMPANY_ID_AND_SITE_ID";
export const SET_COMPANY_SITE_ID = "SET_COMPANY_SITE_ID";
export const SET_COMPANY_ID = "SET_COMPANY_ID";
export const LOAD_SITES_FOR_COMPANY_INITIATED =
  "LOAD_SITES_FOR_COMPANY_INITIATED";
export const LOAD_SITES_FOR_COMPANY_SUCCESS = "LOAD_SITES_FOR_COMPANY_SUCCESS";
export const SET_SITES_FOR_COMPANY = "SET_SITES_FOR_COMPANY";
export const LOAD_SITES_FOR_COMPANY_FAILED = "LOAD_SITES_FOR_COMPANY_FAILED";
export const RESET_SITES_FOR_COMPANY = "RESET_SITES_FOR_COMPANY";
export const LOAD_INTERNAL_USERS_SUCCESS = "LOAD_INTERNAL_USERS_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const ROLE_NONE = "none";
export const ROLE_ADMIN = "admin";
export const VRS_USERS_MIGRATION_SUCCESS = "VRS_USERS_MIGRATION_SUCCESS";
// Generic Crud
export const ACTION_ADD = "ADD";
export const ACTION_EDIT = "EDIT";
export const ACTION_VIEW = "VIEW";
export const ACTION_DELETE = "DELETE";
export const ACTION_CLONE = "CLONE";
export const ACTION_PREVIEW = "PREVIEW";
export const ACTION_SET = "SET";
export const ACTION_SORT = "SORT";
/* export const ACTION_SUBMIT = 'SUBMIT'; */
export const FIELD_TITLE_LENGTH3 = 3;

export const LOAD_DEVICE_ROLE_SUCCESS = "LOAD_DEVICE_ROLE_SUCCESS";

// Report Notification
export const UPDATE_SCHEDULE_REPORT = "UPDATE_SCHEDULE_REPORT";
export const UPDATE_LOCAL = "UPDATE_LOCAL";
export const UPDATE_SELECTED_LOCAL = "UPDATE_SELECTED_LOCAL";
export const UPDATE_SELECTED_GLOBAL = "UPDATE_SELECTED_GLOBAL";

export const RESET_TO_INITIAL_STATE = "RESET_TO_INITIAL_STATE";
export const APP_INITIALISED = "APP_INITIALISED";
export const APP_INITIALISED_WITH_VRS_OPERATIONS =
  "APP_INITIALISED_WITH_VRS_OPERATIONS";
export const SET_SITES = "SET_SITES";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const RESTORE_USER_FROM_SESSION_SUCCESS =
  "RESTORE_USER_FROM_SESSION_SUCCESS";
export const SET_DEFAULT_PAGE_REQUIRED = "SET_DEFAULT_PAGE_REQUIRED";
export const SET_COGNITO_USER_LOADED = "SET_COGNITO_USER_LOADED";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SET_HEADER_TITLE = "SET_HEADER_TITLE";
export const SET_HOME_SELECTION_MODE = "SET_HOME_SELECTION_MODE";
export const SELECT_SITE = "SELECT_SITE";
export const UPDATE_SITE_INITIALISATION = "UPDATE_SITE_INITIALISATION";
export const RESET_SELECTED_SITE_ID = "RESET_SELECTED_SITE_ID";
export const SET_LOADING = "SET_LOADING";

export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const UPDATE_TRANSLATION_FILTER = "UPDATE_TRANSLATION_FILTER";
export const RESET_SITE_DATA = "RESET_SITE_DATA";
export const RESET_APP_DATA = "RESET_APP_DATA";
export const RESET_MULTI_SITE_DATA = "RESET_MULTI_SITE_DATA";
export const MIGRATE_SITES_SUCCESS = "MIGRATE_SITES_SUCCESS";
export const DESIGN_MODULE_UPDATE_SUCCESS = "DESIGN_MODULE_UPDATE_SUCCESS";
export const VRS_OPERATION_ROLES_UPDATED_SUCCESS =
  "VRS_OPERATION_ROLES_UPDATED_SUCCESS";
export const VRS_USER_ROLES_UPDATED_SUCCESS = "VRS_USER_ROLES_UPDATED_SUCCESS";
export const DELETE_UNUSED_ROLES_SUCCESS = "DELETE_UNUSED_ROLES_SUCCESS";
export const VRS_COMPANY_MIGRATION_SUCCESS = "VRS_COMPANY_MIGRATION_SUCCESS";

// Product
export const CREATE_VRS_DEVICE_SUCCESS = "CREATE_VRS_DEVICE_SUCCESS";
export const LOAD_VRS_DEVICES_SUCCESS = "LOAD_VRS_DEVICES_SUCCESS";
export const LOAD_VRS_DEVICE_SUCCESS = "LOAD_VRS_DEVICE_SUCCESS";
export const DELETE_VRS_DEVICE_SUCCESS = "DELETE_VRS_DEVICE_SUCCESS";

//logs
export const LIST_PRINTER_LOGS_SUCCESS = "LIST_PRINTER_LOGS_SUCCESS";
export const GET_PRINTER_LOG_SUCCESS = "GET_PRINTER_LOG_SUCCESS";
export const REQUEST_PRINTER_LOGS_SUCCESS = "REQUEST_PRINTER_LOGS_SUCCESS";

export const SET_COMPANY_DEVICES_LOADED = "SET_COMPANY_DEVICES_LOADED";
export const SET_TREND_VALUES = "SET_TREND_VALUES";
export const RESET_TREND_VALUES = "RESET_TREND_VALUES";

export const LOAD_PLANT_MODELS_SUCCESS = "LOAD_PLANT_MODELS_SUCCESS";
export const LOAD_PLANT_MODELS_INITIATED = "LOAD_PLANT_MODELS_INITIATED";
export const LOAD_PLANT_MODELS_FAILED = "LOAD_PLANT_MODELS_FAILED";
export const LOAD_PLANT_MODELS_RESET = "LOAD_PLANT_MODELS_RESET;"

export const LOAD_PROFILE_SUCCESS = "LOAD_PROFILE_SUCCESS";
export const LOAD_OUTAGE_SUCCESS = 'LOAD_OUTAGE_SUCCESS';

//salesforce
export const LOAD_SALESFORCE_SITES_SUCCESS = "LOAD_SALESFORCE_SITES_SUCCESS";
export const LOAD_SALESFORCE_SERVICE_REGION_GEOMETRY_SUCCESS = "LLOAD_SALESFORCE_SERVICE_REGION_GEOMETRY_SUCCESS";