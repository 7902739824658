import { FormControl, TextField } from "@mui/material";
import { useGlobalStyles } from "../../constants/globalstyles";
import { useTextInputStyle } from "./TextInput.css";
import { useVrsTranslationState } from "../../context/AppContext/AppContext";

interface TextInputProps {
  fieldName: string;
  label: string;
  placeholder: string;
  onChange?: (event) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  value: string | number;
  errors: any;
  isPercent?: boolean;
  type?: string;
  errorInPlantText?: boolean;
  ignoreErrorText?: boolean;
  id?: string;
  textSize?: "medium" | "small";
}

function TextInput({
  fieldName,
  label,
  onChange,
  value,
  isDisabled,
  isRequired,
  placeholder,
  errors,
  type,
  errorInPlantText,
  ignoreErrorText,
  id,
  textSize,
}: TextInputProps) {
  const classes = useTextInputStyle();
  const { _T } = useVrsTranslationState();
  const globalClasses = useGlobalStyles();

  return (
    <div className={classes.inputBox}>
      <FormControl variant="outlined" className={globalClasses.formControl}>
        <TextField
          required={isRequired ? true : false}
          label={label}
          autoComplete="off"
          type={type || "text"}
          name={fieldName}
          variant="outlined"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={isDisabled}
          fullWidth={true}
          error={!!errors[fieldName]}
          size={textSize}
          id={id}
          helperText={
            !ignoreErrorText
              ? errorInPlantText
                ? errors[fieldName]
                : errors[fieldName] && _T(errors[fieldName])
              : ""
          }
        />
      </FormControl>
    </div>
  );
}

TextInput.displayName = "TextInput";

export { TextInput };
