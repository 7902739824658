import { useState, useCallback, useMemo, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Menu, useTheme, useMediaQuery } from '@mui/material';
import Icon from '@mdi/react';
import { mdiInformationOutline, mdiAccountCircleOutline } from '@mdi/js';
import classNames from 'classnames';
import { Menu as MenuIcon, ExitToApp as ExitToAppIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import { List } from '@mui/material';
import { ReactComponent as CompanyLogo } from './videojet-logo.svg';

// styles
import useStyles from './Header.css';

// context
import {
  useAppGlobalDispatch,
  useLogOut,
  useAppSiteState,
  useAppConfigState,
  useAppUserState,
  useVrsTranslationState,
} from '../../context/AppContext/AppContext';
import { useLocation } from 'react-router';
import { toggleSidebar, useLayoutDispatch, useLayoutState } from '../../context/LayoutContext/LayoutContext';
import AddressLabel from '../AddressLabel/AddressLabel';
import PageTitle from '../PageTitle/PageTitle';
import { ProfileMenuItem } from '../ProfileMenuItem/ProfileMenuItem';
import { SimpleLinearProgress } from '../SimpleLinearProgress/SimpleLinearProgress';
import { useConfigActions } from '../../actions/configActions';
import { canAccessExternalVrsPage } from '../../RouteAccess';
import AboutDialog from '../AboutDialog/AboutDialog';
import EditProfileDialog from '../../vrspages/profileandworkschedule/EditProfileDialog/EditProfileDialog';
import { useNavigateToLink } from '../../actions/NavigateActions';
import { formatZuluDateStringToLocalTime } from '../../utilities/utils';
import { OUTAGE_CHECK_MILLISECONDS } from '../../constants/global';
import { useVrsOutageActions } from '../../actions/vrsOutageActions';

function Header() {
  const configActions = useConfigActions();
  const navigate = useNavigateToLink();
  const location = useLocation();
  const classes = useStyles();
  const siteState = useAppSiteState();
  const { sites, selectedSiteId, vrsAbilities } = siteState;
  const { headerTitleInfo, isLoading } = useAppConfigState();
  const { isAuthenticated, userProfile, isAdmin, outageStatus, userProfileLarge } = useAppUserState();

  // global
  const layoutDispatch = useLayoutDispatch();
  const { _T } = useVrsTranslationState();
  const { isSidebarOpened } = useLayoutState();
  const { checkOutageAppSync } = useVrsOutageActions();

  const userDispatch = useAppGlobalDispatch();
  const logOut = useLogOut();

  const theme = useTheme();

  const mediumOrHigher = useMediaQuery(theme.breakpoints.up('md'));
  const smallOrHigher = useMediaQuery(theme.breakpoints.up('sm'));

  // local
  const [profileMenu, setProfileMenu] = useState(null);
  const [showAboutDialog, setShowAboutDialog] = useState(false);
  const [showEditProfileDialog, setShowEditProfileDialog] = useState(false);

  const getSelectedSite = () => {
    if (sites && sites.length > 0 && selectedSiteId) {
      return sites.find((el) => el.id === selectedSiteId);
    }

    if (sites && sites.length > 0 && !selectedSiteId) {
      return sites[0];
    }

    return null;
  };

  const selectedSite = getSelectedSite();

  function isSmallScreen() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    return windowWidth < breakpointWidth;
  }

  const onCloseAboutDialog = useCallback(() => {
    setShowAboutDialog(false);
    setProfileMenu(null);
  }, []);

  const onCloseEditProfileDialog = useCallback(() => {
    setShowEditProfileDialog(false);
    setProfileMenu(null);
  }, []);

  const isHomePage = useCallback(() => location.pathname.toLowerCase().indexOf('/home') > -1, [location]);

  const gotoHome = useCallback(
    (event) => {
      event.preventDefault();
      if (!isHomePage()) {
        configActions.setHomeSelectionMode(2);
        navigate('/home<ts><sql>');
      }
    },
    [configActions, navigate, isHomePage]
  );

  const isUserConfigImporter = !!userProfileLarge?.ListMember?.find(
    (el) => el.Id === 'config_import' && el.MemberType === 'admin'
  );

  const hasAccessVrs = useCallback(
    (requestedClaim) =>
      canAccessExternalVrsPage({
        requestedClaim,
        vrsAbilities,
        isAdmin,
        useForAuthorizationCheck: false,
        isUserConfigImporter,
      }),
    [isAdmin, vrsAbilities]
  );

  const siteDescriptionHideRequired = useMemo(() => {
    const pathname = location.pathname;
    if (
      pathname &&
      (pathname.includes('/api') ||
        pathname.includes('/vrs') ||
        pathname.includes('/home') ||
        pathname.includes('/admin'))
    ) {
      return true;
    }
    return false;
  }, [location.pathname]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isAuthenticated) {
        checkOutageAppSync();
      }
    }, OUTAGE_CHECK_MILLISECONDS);
    return () => clearInterval(interval);
  }, [checkOutageAppSync, isAuthenticated]);

  const getOutageColor = useCallback(() => {
    if (outageStatus && outageStatus.Color) {
      switch (outageStatus.Color.toLowerCase()) {
        case 'red':
          return classes.appBarOutageRed;
        case 'orange':
          return classes.appBarOutageOrange;
        case 'yellow':
          return classes.appBarOutageYellow;
      }
    }
    return '';
  }, [outageStatus, classes]);

  const getOutageText = useCallback(() => {
    if (outageStatus && outageStatus.OutageText) {
      const startDate = outageStatus.OutageStartDate
        ? formatZuluDateStringToLocalTime(outageStatus.OutageStartDate, true)
        : '';

      const endDate = outageStatus.OutageEndDate
        ? formatZuluDateStringToLocalTime(outageStatus.OutageEndDate, true)
        : '';
      return _T(outageStatus.OutageText).replace('[0]', startDate).replace('[1]', endDate);
    }
    return '';
  }, [outageStatus]);

  return (
    <div className={classes.headerTop}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoContainerParent}>
            <div className={classes.logoContainer}>
              <CompanyLogo
                onClick={gotoHome}
                className={classNames(classes.logo, {
                  [classes.link]: !isHomePage(),
                })}
              />
              <IconButton
                color="inherit"
                onClick={() => toggleSidebar(layoutDispatch)}
                className={classes.headerMenuButton}
              >
                <MenuIcon
                  classes={{
                    root: classNames(classes.headerIcon, classes.headerIconCollapse),
                  }}
                />
              </IconButton>
            </div>
            {isLoading && <SimpleLinearProgress />}
          </div>
          {headerTitleInfo && mediumOrHigher && (
            <PageTitle {...headerTitleInfo} titleVisible={mediumOrHigher} breadcrumbVisible={mediumOrHigher} />
          )}
          <div className={classes.grow} />
          {smallOrHigher && (
            <div className={classes.addressTopContainer}>
              {isAuthenticated && (
                <div className={classNames(classes.addressContainerCommon, classes.addressContainer, classes.email)}>
                  {userProfile && userProfile.email}
                </div>
              )}
              {isAuthenticated && selectedSite && !siteDescriptionHideRequired && (
                <>
                  <div
                    className={classNames(
                      classes.addressContainerCommon,
                      isAdmin ? classes.addressContainerAsButton : classes.addressContainer
                    )}
                  >
                    <div className={classes.siteTitle}>{selectedSite.title}</div>
                    <div className={classes.addressLines}>{<AddressLabel address={selectedSite.address} />}</div>
                  </div>
                </>
              )}
            </div>
          )}

          {isAuthenticated && (
            <div className={classes.profileBtnContainer}>
              <IconButton
                aria-haspopup="true"
                color="inherit"
                data-testid={'user-btn'}
                aria-controls="profile-menu"
                onClick={(e: any) => setProfileMenu(e.currentTarget)}
              >
                <AccountCircleIcon classes={{ root: classes.headerAccountIcon }} />
              </IconButton>
            </div>
          )}
          <Menu
            data-testid="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            {!mediumOrHigher && (
              <div className={classes.profileMenuItem}>
                <div className={classes.profileMenuLink}>{userProfile && userProfile.email}</div>
              </div>
            )}
            <List component="nav" aria-label="profile menu">
              {hasAccessVrs('edit-profile') && (
                <ProfileMenuItem
                  icon={
                    <div className={classes.icon}>
                      <Icon path={mdiAccountCircleOutline} size={1} />
                    </div>
                  }
                  linkLabel={_T('Profile')}
                  onClickMenuItem={() => setShowEditProfileDialog(true)}
                  testId="profile-btn"
                />
              )}
              <ProfileMenuItem
                icon={
                  <div className={classes.icon}>
                    <Icon path={mdiInformationOutline} size={1} />
                  </div>
                }
                linkLabel={_T('About')}
                onClickMenuItem={() => setShowAboutDialog(true)}
                testId="about-btn"
              />
              <ProfileMenuItem
                icon={
                  <div className={classes.icon}>
                    <ExitToAppIcon />
                  </div>
                }
                linkLabel={_T('Logout')}
                onClickMenuItem={() => logOut(userDispatch)}
                testId="logout-btn"
              />
            </List>
          </Menu>
        </Toolbar>
      </AppBar>
      {!mediumOrHigher && (
        <AppBar className={classes.appBarBreadcrumb}>
          <Toolbar className={classes.toolbar} classes={{ root: classes.toolBarBreadcrumb }}>
            {headerTitleInfo && <PageTitle {...headerTitleInfo} breadcrumbVisible={true} titleVisible={false} />}
          </Toolbar>
        </AppBar>
      )}
      {outageStatus && (
        <AppBar
          className={classNames(
            classes.appBarOutage,
            isSidebarOpened && smallOrHigher ? classes.appBarOutageToolBarOpen : '',
            mediumOrHigher ? classes.outagePosition1 : classes.outagePosition2,
            getOutageColor()
          )}
        >
          <Toolbar className={classes.toolbar} classes={{ root: classes.outageBar }}>
            <div
              className={isSidebarOpened && !isSmallScreen() ? classes.outageTextParentOpen : classes.outageTextParent}
            >
              <div className={classes.outageText}>{getOutageText()}</div>
            </div>
          </Toolbar>
        </AppBar>
      )}
      {showAboutDialog && <AboutDialog open={showAboutDialog} onClose={onCloseAboutDialog} />}
      {showEditProfileDialog && <EditProfileDialog open={showEditProfileDialog} onClose={onCloseEditProfileDialog} />}
    </div>
  );
}

Header.displayName = 'Header';

export default Header;
