import { invokeAppSync } from "../libs/authLib";
import { getApiPrefix } from "../libs/apiLib";
import config from "../config";
import { IUserProfile } from "../interfaces/User/IUserProfile";
import { ILanguageItem } from "../interfaces/User/ILanguageItem";
import { IUserInput } from "../interfaces/User/IUserInput";
import { ITrackerInput } from "../interfaces/User/ITrackerInput";
import { timeScaleOptions } from "./apiUtil";
import { BASE_DISPLAY_UNIT } from "../constants/global";

const baseAppSyncUrl = () => getApiPrefix();

const checkAppSyncUrlExists = () => {
  return !!config.appSync["VRS_APPSYNC_USER_URL"];
};

class VrsUserApi {
  static getLanguageItemsAppSync(cultureName: string, languageCode: string) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve([]);
    }
    
    const graphQLBody = {
      query: `
          query LanguageItemsQueryTimescale {
            languageItems(cultureName: "${cultureName}", languageCode: "${languageCode}", options: ["ts"]) {
              Value
              Identifier
            }
          }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_SYSTEM",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.languageItems) {
        const languageItems: Array<ILanguageItem> = [];
        const languageItemObj: any = {};

        const items = results?.data?.languageItems || [];

        for (const el of items) {
          languageItems.push({
            v: el.Value,
            i: el.Identifier,
          });
          languageItemObj[el.Identifier] = el.Value;
        }

        console.log('cultureName=', cultureName);

        // Now we need to add simplified identifier (space and _ removed) to the language item list
        for (const el of items) {
          if (el.Identifier) {
            const identifierWithoutSpace = el.Identifier.toString()
              .replace(/\s/g, "");
            if (!languageItemObj[identifierWithoutSpace]) {
              languageItems.push({
                v: el.Value,
                i: identifierWithoutSpace,
              });
            }
          }
        }
        return languageItems;
      }
      return [];
    });
  }

  static getUserProfileAppSync(includeUserAlerts = false) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve([]);
    }

    const [options, queryExtra] = timeScaleOptions();


    const userAlerts = includeUserAlerts
      ? `
    UserAlerts {
      AccessLevelName
      Description
      EventClassName
      EventCodeName
      ModelName
      PlantName
    }
    `
      : "";

    const graphQLBody = {
      query: `
      query ProfileQuery${queryExtra} {
        profile(${options}) {
          Country
          EmailAddress
          FirstName
          LanguageId
          NotificationEmail
          Languages {
            EnglishName
            IetflanguageTag
            IsDefaultForLanguageCode
            LanguageCode
            LanguageId
            LocalName
          }
          LastName
          PhoneNumber
          UserId
          AccessLevel
          AccessLevelId
          PlantIDs
          DisplayUnit
          Prefer24HourTimeFormat
          WorkSchedules {
            EnableNotification
            Schedule {
              Day
              ShiftHours
              ShiftMins
              StartHour
              StartMinute
            }
          }
          ServiceOutageStatus {
            Color
            IsOutageOn
            OutageEndDate
            OutageId
            OutageStartDate
            OutageText
          }
          ListMember {
            Id
            MemberType
          }
          ${userAlerts}
        }
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_USER",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.profile) {
        return [{...results.data?.profile, DisplayUnit: results.data?.profile.DisplayUnit || BASE_DISPLAY_UNIT}];
      }
      return [];
    });
  }

  static getUserRegionsAppSync() {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve([]);
    }

    const [options, queryExtra] = timeScaleOptions();

    const graphQLBody = {
      query: `
      query ServiceRegionQuery${queryExtra} {
        profile(${options}) {
          ServiceRegions {
            Id
          }
        }
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_USER",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.profile) {
        return [results.data?.profile];
      }
      return [];
    });
  }

  static saveUserProfileAppSync(
    profileInput: IUserProfile,
    includeUserAlerts: boolean
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({
        error: null,
        data: null,
      });
    }

    const userAlerts = includeUserAlerts
      ? `
    UserAlerts {
      AccessLevelName
      Description
      EventClassName
      EventCodeName
      ModelName
      PlantName
    }
    `
      : "";

    console.log('profileInput', profileInput);

    const graphQLBody = {
      query: `
      mutation SaveProfileMutation {
        saveProfile(profileInput: {
          FirstName: "${profileInput.FirstName}",
          LastName: "${profileInput.LastName}",
          PhoneNumber: "",
          NotificationEmail: "${profileInput.NotificationEmail}",
          EmailAddress: "${profileInput.EmailAddress}",
          LanguageId: ${profileInput.LanguageId},
          CurrentPassword: "${profileInput.CurrentPassword || ""}",
          NewPassword: "${profileInput.NewPassword || ""}",
          Country: "${profileInput.Country}",
          AccessLevel: "${profileInput.AccessLevel}",
          AccessLevelId: "${profileInput.AccessLevelId}",
          Prefer24HourTimeFormat: ${profileInput.Prefer24HourTimeFormat},
          DisplayUnit: "${profileInput.DisplayUnit || BASE_DISPLAY_UNIT}",
          PlantIDs:"${profileInput.PlantIDs}",
          WorkScheduleData: {
            Schedule: [${profileInput.WorkSchedules.Schedule.map((el) => {
              return `{Day: ${el.Day},
              StartHour: ${el.StartHour},
              StartMinute: ${el.StartMinute},
              ShiftHours: ${el.ShiftHours},
              ShiftMins: ${el.ShiftMins}}`;
            })}], 
              EnableNotification: ${
                profileInput.WorkSchedules.EnableNotification
              }, 
              TimeZoneId: 1
          }
        }) {
          Country
          EmailAddress
          FirstName
          LanguageId
          NotificationEmail
          Languages {
            EnglishName
            IetflanguageTag
            IsDefaultForLanguageCode
            LanguageCode
            LanguageId
            LocalName
          }
          LastName
          PhoneNumber
          UserId
          AccessLevel
          AccessLevelId
          Prefer24HourTimeFormat
          DisplayUnit
          PlantIDs
          WorkSchedules {
            EnableNotification
            Schedule {
              Day
              ShiftHours
              ShiftMins
              StartHour
              StartMinute
            }
          }
          ${userAlerts}
        }
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_USER",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.saveProfile) {
        return {
          error: null,
          data: results.data?.saveProfile,
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }

  static getCountriesAppSync() {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve([]);
    }

    const [options, queryExtra] = timeScaleOptions();

    const graphQLBody = {
      query: `
      query CountriesQuery${queryExtra} {
        countries(${options}) {
          CountryId
          Name
        }
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_USER",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.countries) {
        return results.data?.countries;
      }
      return [];
    });
  }

  static getLanguagesAppSync() {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve([]);
    }

    const [options, queryExtra] = timeScaleOptions();

    const graphQLBody = {
      query: `
      query LanguagesQuery${queryExtra}{
        languages(${options}) {
          EnglishName
          IETFLanguageTag
          LanguageID
          LocalName
        }
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_SYSTEM",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.languages) {
        return results.data?.languages;
      }
      return [];
    });
  }

  static getAccessLevelsAppSync() {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve([]);
    }

    const [options, queryExtra] = timeScaleOptions();

    const graphQLBody = {
      query: `
      query AccessLevelsQuery${queryExtra} {
        accessLevels(${options}) {
          Name
          Value
          External
        }
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_USER",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.accessLevels) {
        return results.data?.accessLevels;
      }
      return [];
    });
  }

  static getUsersAppSync(companyId: string, allUsers: boolean) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve([]);
    }

    const [options, queryExtra] = timeScaleOptions();

    const graphQLBody = {
      query: `
      query UsersQuery${queryExtra} {
        users(companyId: "${
          companyId === "0" ? "" : companyId
        }", allUsers: ${allUsers}, ${options}) {
          UserId
          EmailAddress
          NotificationEmail
          FirstName
          LastName
          CompanyId
          PlantIDs
          CountryName
          AccessLevelName
          AccessLevelId
          Disabled
          IsDeleted
          LanguageId
          CountryId
          EnableNotification
          UserNotes
          IsCountryUser
        }
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_USER",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.users) {
        return results.data?.users;
      }
      return [];
    });
  }
  static saveUserAppSync(userInput: IUserInput) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({
        error: null,
        data: null,
      });
    }

    const graphQLBody = {
      query: ` mutation SaveUserMutation
      {
        saveUser(userInput: {
          UserId: "${userInput.UserId}"
          FirstName: "${userInput.FirstName}"
          LastName: "${userInput.LastName}"
          EmailAddress: "${userInput.EmailAddress}"
          NotificationEmail: "${userInput.NotificationEmail}"
          EnableNotification: "${userInput.EnableNotification}"
          PhoneNumber: "${userInput.PhoneNumber}"
          UserNotes: "${userInput.UserNotes}"
          Password: "${userInput.Password}"
          ConfirmPassword: "${userInput.ConfirmPassword}"
          AccessLevelId: "${userInput.AccessLevelId}"
          PlantId: ${userInput.PlantId}
          CompanyId: "${userInput.CompanyId}"
          LanguageId: ${userInput.LanguageId}
          CountryId: ${userInput.CountryId}
          Disabled: "${userInput.Disabled}"
          IsCountryUser: "${userInput.IsCountryUser}"
          DeleteUser: "${userInput.DeleteUser}"
          RestoreDeletedUser: "${userInput.RestoreDeletedUser}"
          ExtraOptions: "${userInput.ExtraOptions}"
          DoesDeleteCompletely: "${userInput.DoesDeleteCompletely}"
          PlantIDs: "${userInput.PlantIDs}"
        }) {
            ExtraOptions
            IsAccessLevelChanged
            UserChangedOwnEmail
            Action
            User {
              UserId
              EmailAddress
              NotificationEmail
              FirstName
              LastName
              PlantIDs
              CountryName
              CountryId
              CompanyId
              AccessLevelName
              AccessLevelId
              Disabled
              IsDeleted
              EnableNotification
              LanguageId
              UserNotes
              IsCountryUser
            }
          }
        }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_USER",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.saveUser) {
        return {
          error: null,
          data: results.data?.saveUser,
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }

  static saveTrackerAppSync(trackerInput: ITrackerInput) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({
        error: null,
        data: null,
      });
    }

    const graphQLBody = {
      query: ` mutation SaveTrackerMutation
      {
        saveTracker(trackerInput: {
          CompanyId: "${trackerInput.CompanyId}"
          PlantId: ${trackerInput.PlantId}
          Url: "${trackerInput.Url}"
          ActionText: "${trackerInput.ActionText}"
        })
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_USER",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.saveTracker) {
        return {
          error: null,
          data: results.data?.saveTracker,
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }

  static saveSalesforceTrackerAppSync(
    TrafficSource: string,
    TrafficID: string,
    SFDCRecord: string,
    accessType: string
  ) {
    if (!checkAppSyncUrlExists()) {
      return Promise.resolve({
        error: null,
        data: null,
      });
    }

    const graphQLBody = {
      query: ` mutation SalesforceTrackerMutation
      {
        saveSalesforceTracker(
          TrafficSource: "${TrafficSource}",
          TrafficID: "${TrafficID}",
          SFDCRecord: "${SFDCRecord}",
          AccessType: "${accessType}")
      }
        `,
    };

    return invokeAppSync({
      gatewayName: "VRS_APPSYNC_USER",
      path: baseAppSyncUrl(),
      method: "POST",
      body: graphQLBody,
    }).then((results) => {
      if (results.data?.saveSalesforceTracker) {
        return {
          error: null,
          data: results.data?.saveSalesforceTracker,
        };
      }

      return {
        error:
          results.errors && results.errors.length > 0
            ? results.errors[0]
            : results.errors,
        data: null,
      };
    });
  }
}

export default VrsUserApi;
