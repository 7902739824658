import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";

export const useProgressIndicatorStyles = makeStyles((theme: Theme) => ({
  spinner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  placeholder: {
    height: theme.spacing(5)
  },
  overlayWithDrawer: {
    position: 'fixed',
    width: 'calc(100% - 320px)',
    height: 'calc(100% - 100px)',
    zIndex: 999999,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: "middle",
  },
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: "middle",
    width: '100%',
    height: 'calc(100% - 100px)',
    zIndex: 999999
  },
  withBackground: {
    background: 'rgba(0,0,0,.20)',
  }
}));
