import AWS from "aws-sdk";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import sigV4Client from "./sigV4Client";
import config from "../config";
import { cognitoFields } from "vccm-common";
import { callExternalApi } from "./apiLib";
import JwtDecode from "jwt-decode";
import authLib from "./authLib";
import Logger from "../utilities/Logger/Logger";
import { getExternalCallDelay } from "../utilities/utils";

export function invokeAppSync(apiParam: any): Promise<any> {
  return invokeApig({ ...apiParam, isAppSync: true });
}
export function invokeApig(apiParam: any): Promise<any> {
  console.log("apiParam", apiParam);
  const {
    gatewayName,
    path,
    method = "GET",
    headers = {},
    queryParams = {},
    body,
    isAppSync,
    retryInTimeOut,
  } = apiParam;

  return new Promise((resolve, reject) => {
    let tryNumber = 1;
    authUser()
      .then(({ idToken }) => {
        if (!idToken) {
          reject(new Error("User is not logged in"));
        }

        let gatewayUrlKey = "URL";
        if (gatewayName) gatewayUrlKey = gatewayName + "_URL";

        console.log("gatewayUrlKey=", gatewayUrlKey);

        const credentials: any = AWS.config.credentials;

        const signedRequest = sigV4Client
          .newClient({
            accessKey: credentials.accessKeyId,
            secretKey: credentials.secretAccessKey,
            sessionToken: credentials.sessionToken,
            region: isAppSync
              ? config.appSync.REGION
              : config.apiGateway.REGION,
            endpoint: isAppSync
              ? config.appSync[gatewayUrlKey]
              : config.apiGateway[gatewayUrlKey],
          })
          .signRequest({
            method,
            path,
            headers,
            queryParams,
            body,
          });

        const callFunc = () => {
          return callExternalApi(
            method,
            signedRequest.url,
            body !== null ? JSON.stringify(body) : null,
            { headers: { ...headers, Authorization: idToken } }
          ).then((results) => {
            if (results.status === 204) {
              return {};
            }

            const errors = results.data?.errors;
            const externalCallDelay = getExternalCallDelay(body);

            if (errors && errors.length > 0 && errors[0].errorType) {
              console.log(
                "errorType=",
                errors && errors.length > 0 && errors[0].errorType
              );
              console.log("externalCallDelay=", externalCallDelay);
            }

            tryNumber++;
            if (
              tryNumber <= 3 &&
              errors &&
              errors.length > 0 &&
              (errors[0].errorType === "SqlException" ||
                (retryInTimeOut &&
                  errors[0].errorType === "Lambda:ExecutionTimeoutException"))
            ) {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  console.log(`Trying ${tryNumber}. time`);
                  callFunc()
                    .then((results) => {
                      resolve(results);
                    })
                    .catch((err) => {
                      reject(err);
                    });
                }, externalCallDelay);
              });
            } else {
              return results.data;
            }
          });
        };

        return callFunc();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function invokeApigAnonymously(apiParam: any): Promise<any> {
  console.log("apiParam", apiParam);
  const {
    gatewayName,
    path,
    method = "GET",
    headers = {},
    body,
    isAppSync,
  } = apiParam;

  return new Promise((resolve, reject) => {
    authUser()
      .then(() => {
        let gatewayUrlKey = "URL";
        if (gatewayName) gatewayUrlKey = gatewayName + "_URL";

        console.log("gatewayUrlKey=", gatewayUrlKey);

        const endpoint = isAppSync
          ? config.appSync[gatewayUrlKey]
          : config.apiGateway[gatewayUrlKey];

        const url = endpoint + path;

        console.log("url=", url);

        return callExternalApi(
          method,
          url,
          body !== null ? JSON.stringify(body) : null,
          { headers: { ...headers } }
        ).then((results) => {
          if (results.status === 204) {
            return {};
          }
          return results.data;
        });
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function s3Upload(file) {
  if (!(await authUser())) {
    throw new Error("User is not logged in");
  }

  const s3: any = new AWS.S3({
    params: {
      Bucket: config.s3.BUCKET,
    },
  });
  const filename = `${
    (AWS.config.credentials as any).identityId
  }-${Date.now()}-${file.name}`;

  return s3
    .upload({
      Key: filename,
      Body: file,
      ContentType: file.type,
      ACL: "public-read",
    })
    .promise();
}

export function isLoggedIn(): boolean {
  return getCurrentUser() != null;
}

export async function authUser(): Promise<any> {
  const currentUser = getCurrentUser();
  if (currentUser === null) {
    return false;
  }

  const idToken = await getUserToken(currentUser);

  if (
    !AWS.config.credentials ||
    Date.now() >= (AWS.config.credentials as any).expireTime - 60000
  ) {
    await getAwsCredentials(idToken);
  }

  return { currentUser, idToken };
}

export function signOutUser() {
  const currentUser = getCurrentUser();

  if (currentUser !== null) {
    currentUser.signOut();
  }

  if (AWS.config.credentials) {
    (AWS.config.credentials as any).clearCachedId();
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({} as any);
  }
}

export function getUserToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession(function (err, session) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session.getIdToken().getJwtToken());
    });
  });
}

export async function userRefreshSession(cognitoUser) {
  return new Promise((resolve, reject) => {
    if (!cognitoUser)
      reject(new Error("UserHelperRefresh.refreshSession: no user"));
    cognitoUser.getSession((getSessionErr, cognitoUserSession) => {
      if (getSessionErr) {
        reject(
          new Error(
            `UserHelperRefresh.refreshSession: ${JSON.stringify(getSessionErr)}`
          )
        );
      }
      const refreshToken = cognitoUserSession.getRefreshToken();
      cognitoUser.refreshSession(refreshToken, (refreshSessionErr, token) => {
        if (refreshSessionErr) {
          reject(
            new Error(
              `UserHelperRefresh.refreshSession: ${JSON.stringify(
                getSessionErr
              )}`
            )
          );
        }
        resolve(token);
      });
    });
  });
}

export function getCurrentUser() {
  const userPool = new CognitoUserPool({
    UserPoolId: config.cognito.USER_POOL_ID,
    ClientId: config.cognito.APP_CLIENT_ID,
  });
  return userPool.getCurrentUser();
}

export async function getUserIdAttributes(currentUser) {
  if (currentUser.getSession instanceof Function)
    return currentUser.getSession(async (err, data) => {
      if (err) throw err;
      const yourIdToken: string =
        (data && data.idToken && data.idToken.jwtToken) || "";
      return yourIdToken ? JwtDecode(yourIdToken) : null;
    });
  // else
  return Promise.resolve(currentUser);
}

export function checkIsAdmin(currentUser): boolean {
  const companyList =
    currentUser &&
    currentUser.signInUserSession &&
    currentUser.signInUserSession.idToken &&
    currentUser.signInUserSession.idToken.payload &&
    currentUser.signInUserSession.idToken.payload[
      cognitoFields.COMPANY_ID_LIST
    ];
  return companyList && companyList.includes("*");
}

export function hasIdentities(currentUser): boolean {
  return currentUser &&
    currentUser.signInUserSession &&
    currentUser.signInUserSession.idToken &&
    currentUser.signInUserSession.idToken.payload &&
    currentUser.signInUserSession.idToken.payload.identities
    ? true
    : false;
}

export async function isVrsSuperUser(currentUser): Promise<boolean> {
  let result = false;

  try {
    const tokenDecoded = await authLib.getUserIdAttributes(currentUser);
    const authClaims: any = tokenDecoded.authClaims
      ? JSON.parse(tokenDecoded.authClaims)
      : {};

    result = !!(
      authClaims &&
      authClaims.VrsGlobal &&
      authClaims.VrsGlobal.vrsSuperUser === "1"
    );
  } catch (e) {
    Logger.of("isVrsSuperUser").warn("error", e);
  }

  return result;
}

export async function checkIsCSEUser(currentUser): Promise<boolean> {
  let result = false;

  try {
    const tokenDecoded = await authLib.getUserIdAttributes(currentUser);
    const authClaims: any = tokenDecoded.authClaims
      ? JSON.parse(tokenDecoded.authClaims)
      : {};

    result = !!(
      authClaims &&
      authClaims.VrsInternal &&
      authClaims.VrsInternal.vrsOperations === "8"
    );
  } catch (e) {
    Logger.of("checkIsCSEUser").warn("error", e);
  }

  return result;
}

export async function checkIsVJInternalUser(currentUser): Promise<boolean> {
  let result = false;

  try {
    const tokenDecoded = await authLib.getUserIdAttributes(currentUser);
    const authClaims: any = tokenDecoded.authClaims
      ? JSON.parse(tokenDecoded.authClaims)
      : {};

    result = !!(
      authClaims &&
      authClaims.VrsInternal
    );
  } catch (e) {
    Logger.of("checkIsVJInternalUser").warn("error", e);
  }

  return result;
}

export async function checkIsServiceUser(currentUser): Promise<boolean> {
  let result = false;

  try {
    const tokenDecoded = await authLib.getUserIdAttributes(currentUser);
    const authClaims: any = tokenDecoded.authClaims
      ? JSON.parse(tokenDecoded.authClaims)
      : {};

    result = !!(
      authClaims &&
      authClaims.VrsInternal &&
      authClaims.VrsInternal.vrsOperations === "2"
    );
  } catch (e) {
    Logger.of("checkIsCSEUser").warn("error", e);
  }

  return result;
}

export async function getAuthClaims(currentUser): Promise<any> {
  let result = null;

  try {
    const tokenDecoded = await authLib.getUserIdAttributes(currentUser);
    const authClaims: any = tokenDecoded.authClaims
      ? JSON.parse(tokenDecoded.authClaims)
      : {};

    result = authClaims;
  } catch (e) {
    Logger.of("getAuthClaims").warn("error", e);
  }

  return result;
}

export async function isPureDesignUser(currentUser): Promise<boolean> {
  let result = false;

  try {
    const tokenDecoded = await authLib.getUserIdAttributes(currentUser);
    if (tokenDecoded && tokenDecoded[cognitoFields.COMPANY_ID_LIST]) {
      const companyList =
        tokenDecoded[cognitoFields.COMPANY_ID_LIST].split("|");

      result =
        companyList.length === 1 && companyList[0] === currentUser.username;
    }
  } catch (e) {
    Logger.of("isPureDesignUser").warn("error", e);
  }

  return result;
}

export function getUserCompanyName(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getUserAttributes((err, attributes) => {
      if (err) reject(err);
      else {
        const companyAttr = attributes.find(
          (attr) => attr.getName() === cognitoFields.COMPANY_NAME
        );
        resolve((companyAttr && companyAttr.getValue()) || "My Site");
      }
    });
  });
}

export function getUserName(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getUserAttributes((err, attributes) => {
      if (err) reject(err);
      else {
        const firstName = attributes.find(
          (attr) => attr.getName() === "given_name"
        );
        const lastName = attributes.find(
          (attr) => attr.getName() === "family_name"
        );
        resolve(`${firstName.getValue()} ${lastName.getValue()}`);
      }
    });
  });
}

export function getUserEmail(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getUserAttributes((err, attributes) => {
      if (err) reject(err);
      else {
        const emailAttr = attributes.find((attr) => attr.getName() === "email");
        resolve((emailAttr && emailAttr.getValue()) || "@undefined");
      }
    });
  });
}

// after researching, I agree with the analysis here:
// https://github.com/AnomalyInnovations/serverless-stack-com/issues/113#issuecomment-346095708
// basically, multiple calls, trying to get the aws credentials can lead to overwriting the
// credentials, this will make later secrets invalid for the previous call

let loadingCredentials = null;
async function getAwsCredentials(userToken) {
  // currently have a request in progress, return that promise
  if (loadingCredentials) return loadingCredentials;
  const authenticator = `cognito-idp.${config.cognito.REGION}.amazonaws.com/${config.cognito.USER_POOL_ID}`;

  AWS.config.update({ region: config.cognito.REGION });

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
    Logins: {
      [authenticator]: userToken,
    },
  });

  loadingCredentials = (AWS.config.credentials as any).getPromise();
  // awaiting the promise so we can reset the loading variable after the request is completed
  await loadingCredentials;
  loadingCredentials = null;
}
